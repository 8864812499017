.action-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 10px 10px;

  .pagination {
    margin: 10px 0;
    padding-left: 20px;
    display: flex;
    align-items: center;
    width: 50%;

    button {
      background-color: transparent;
      border: 1px solid #ccc;
      border-radius: 4px;
      display: flex;
      cursor: pointer;
      padding: 5px 10px;
      margin-right: 5px;

      &:hover {
        border: 1px solid #8c56e1;
      }
    }

    button.active {
      background-color: #8c56e1;
      border-color: #ccc;
    }

    button:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .page-info {
      color: grey;
      margin-left: 10px;
      width: 200px;
    }
  }

  .page-size {
    margin-right: 20px;

    label {
      margin-right: 10px;
    }

    select {
      background-color: transparent;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      padding: 5px 10px;

      &:hover {
        border: 1px solid #8c56e1;
      }
    }
  }
}

.show-more{
  border-radius: 0 0 10px 10px;
}