/* Active state styles */
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pricing {
  width: 120px;
  margin: 3rem auto;
}

.login,
.register {
  height: 2.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.main-title {
  width: 30%;
  margin: 4rem 0 3rem 0;
}

.main-subtitle {
  width: 12%;
  margin: 0 0 2rem 15rem;
}

.container-main {
  justify-content: space-between;
  width: 100%;

  .left-container {
    display: flex;
    width: 22%;
    margin-left: 10rem;

    .bar {
      height: 90%;
      width: 2px;
      background-color: #dadfe5;
      margin-right: 1rem;
      margin-left: 5rem;
      transition: background-color 0.3s ease;
    }

    /* Add styles for the active state of the bar */
    .bar-active {
      width: 3px;
      background-color: #0e1012;
    }
    .title-container {
      .title-active {
        font-weight: bold;
        font-size: 32px;
        color: #000;
      }

      .subtitle-active {
        font-size: 16px;
        color: #4f5a65;
        margin-bottom: 5rem;
      }

      /* Inactive state styles */
      .title-inactive {
        font-weight: bold;
        font-size: 32px;
        color: #888;
        cursor: pointer;
      }

      .subtitle-inactive {
        font-size: 16px;
        color: #ccc;
        margin-bottom: 5rem;
      }
    }
  }

  .right-container {
    margin-right: 5rem;

    .image {
      width: 650px;
      margin-right: 5rem;
    }
  }
}

/* MainContainer.scss */

/* Define styles for the main container */
.main-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
  position: relative;
  z-index: 1;

  /* Define styles for the first container */

  .container {
    width: 356px;
    border-radius: 40px;
    margin-bottom: 10px;
    position: relative;
    .icon {
      margin: 1.875rem 0 0 1.875rem;
    }

    .text {
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
      margin: 1.5625rem 0 0.625rem 1.25rem;

      .subtext {
        width: 70%;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }

  .container1 {
    height: 400px;
    background-color: #8c56e1;
    margin-right: 0.9375rem;

    .innovate {
      position: absolute;
      top: 38px;
      left: 85%;
      transform: translate(-50%, 100%) rotate(-90deg);
      font-size: 72px;
      font-weight: bold;
      color: #8040d6;
    }

    .button {
      margin: 1.625rem 0 0 1.5625rem;
    }
  }

  /* Define styles for the second container */
  .container2 {
    height: 500px;
    background-color: #a4d165;
    margin-right: 0.9375rem;

    .sharepass {
      position: absolute;
      top: 88px;
      left: 85%;
      transform: translate(-50%, 100%) rotate(-90deg);
      font-size: 72px;
      font-weight: bold;
      color: #80aa43;
    }

    .button {
      margin: 8.125rem 0 0 1.5625rem;
    }
  }

  /* Define styles for the third container */
  .container3 {
    height: 600px;
    background-color: #9caab9;

    .transparency {
      position: absolute;
      top: 140px;
      left: 85%;
      transform: translate(-50%, 100%) rotate(-90deg);
      font-size: 72px;
      font-weight: bold;
      color: #868e97;
    }

    .button {
      margin: 14.375rem 0 0 1.5625rem;
    }
  }

  .middle-container {
    width: 100%;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-color: rgba(245, 246, 243, 1);

    .small-black-container {
      background-image: linear-gradient(to bottom, #36373a 0%, #282d33 100%);
    }

    .small-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-left: 15%;
      overflow: hidden;

      .navigation {
        color: white;
        margin-right: 8rem;

        .heading {
          font-size: 24px;
          color: #8395a7;
        }
        .body {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 40px;
            margin: 0 1rem;
          }

          button {
            min-width: 30px;
          }

          .commas {
            position: absolute;
            left: 211px;
            bottom: 153px;
          }
        }
      }
    }

    .black-container-img {
      width: 200px;
      margin: 0 auto 10rem;
      padding-top: 18rem;
    }

    .testimonial {
      width: 60%;
      white-space: nowrap;
      display: flex;

      .testimonial-container {
        background-color: #353c43;
        width: 552px;
        height: 270px;
        padding: 20px;
        border-radius: 15px;
        margin-top: 20px;
        margin-right: 1.25rem;
        flex-shrink: 0;

        .testimonial-body {
          display: flex;
          flex-direction: column;
        }
      }
    }

    /* Define styles for the testimonial text */
    .testimonial-text {
      width: 90%;
      height: 60%;
      font-size: 18px;
      color: #ffffff;
      text-wrap: wrap;
      margin: 0 0 3.25rem 0.75rem;
    }

    /* Define styles for the testimonial info container */
    .testimonial-info {
      display: flex;
      align-items: center;
      margin-left: 0.75rem;
    }

    /* Define styles for the profile image */
    .profile-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 0.625rem;
    }

    /* Define styles for the name */
    .name {
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 3px;
    }

    /* Define styles for the stars */
    .stars {
      margin-top: 2px;
    }
    .try-now {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
      .button {
        color: #ffff;
        background-color: #8dc63f;
        border: 1px solid transparent;
        padding: 8px 30px;
        font-size: 16px;
        border-radius: 5px;
      }
    }

    .numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;

      .numbers-image {
        width: 80%;
        margin-top: 5rem;
      }
    }

    .work-title {
      display: flex;
      justify-content: center;
      margin-top: 4rem;

      .whywork-title {
        width: 413px;
      }
    }

    .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 400px;
      background-color: #16092b;
      color: white;
      margin-top: 10rem;

      .headings {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
      }

      .join-community,
      .more-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          color: #ffff;
          font-size: 42px;
        }
      }

      .details-links {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: #b9b5bf;
        width: 100%;
        margin-top: 2rem;
        padding-left: 8.125rem;

        a {
          margin-top: 1rem;
        }

        /* Add the following CSS to arrange the links in two columns */
        .row {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 1rem;
        }

        /* Adjust the width of the links to fit two in a row */
        .row a {
          margin-left: 3.125rem;
        }

        .arrow-right {
          margin-left: 8rem;
        }
      }

      .social-links {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: #b9b5bf;
        width: 100%;
        margin-top: 3rem;

        a {
          margin-bottom: 2rem;
        }

        .details-links a:last-child {
          margin-bottom: 0;
        }

        /* Add the following CSS to arrange the links in two columns */
        .row {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-bottom: 1rem;
        }

        /* Adjust the width of the links to fit two in a row */
        .row a {
          width: 50%;
          box-sizing: border-box;
          text-align: center;
        }

        .arrow-right {
          margin-left: 8rem;
        }
      }

      .details-links a:last-child {
        margin-bottom: 0;
      }

      .social-links a,
      .details-links a {
        border-bottom: 2px solid #2d2240;
        padding-bottom: 8px;
        display: flex;
        align-items: center;

        .facebook,
        .instagram {
          margin-right: 0.625rem;
        }

        .arrow {
          margin: 0 7.5rem;
        }
      }

      .copyright {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 30px;
        background-color: #210d41;
        bottom: 0;

        p {
          color: #7a6e8d;
        }
      }

      .back-top {
        position: absolute;
        right: 0;
        margin-bottom: 400px;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .toggle-button {
    transition: all 0.8 ease;
  }

  .small-screen-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 8px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .small-screen-header img {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .pricing {
    width: 100px;
    margin: 3rem auto;
  }

  .image-info-mobile {
    width: 70%;
    text-align: left;
  }

  .image-title-mobile {
    font-size: 20px;
    font-weight: bold;
  }

  .subtitle-mobile {
    font-size: 16px;
    color: #4f5a65;
  }

  .login,
  .register {
    width: 45%;
    height: 2.5rem;
    padding: 0 2rem 0 2rem;
    margin: 2rem 2.4rem 2rem 0;
  }

  .main-title {
    width: 80%;
    margin: 2rem 0 1.5rem 0;
  }

  .main-subtitle {
    width: 35%;
    margin: 0 0 2rem 2rem;
  }

  .container-main {
    flex-direction: column;
    width: 100%;

    .left-container {
      display: flex;
      align-items: center;
      width: 100%;
      margin-left: 1rem;
      order: 2;

      .bar {
        height: 0;
        width: 2px;
        background-color: #dadfe5;
        margin-right: 0;
        margin-left: 0;
        transition: background-color 0.3s ease;
      }

      /* Add styles for the active state of the bar */
      .bar-active {
        width: 3px;
        background-color: #0e1012;
      }

      .title-container {
        width: 100%;
        white-space: nowrap;
        display: flex;
        overflow: scroll;
        margin-left: 5.5rem;

        .title-active {
          font-weight: bold;
          font-size: 20px;
          color: #000;
        }

        .subtitle-active {
          font-size: 16px;
          color: #4f5a65;
          margin-bottom: 5rem;
          margin-right: 4rem;
        }

        /* Inactive state styles */
        .title-inactive {
          font-weight: bold;
          font-size: 20px;
          color: #888;
          cursor: pointer;
        }

        .subtitle-inactive {
          font-size: 16px;
          color: #ccc;
          margin-bottom: 5rem;
          margin-right: 4rem;
        }
      }
    }

    .right-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      order: 1;

      .image {
        width: 650px;
        margin-right: 0;
      }
    }
  }

  /* MainContainer.scss */

  /* Define styles for the main container */
  .main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
    z-index: 1;

    .container {
      width: 80%;
      border-radius: 40px;
      margin-bottom: 10px;
      position: relative;
      .icon {
        margin: 30px 0 0px 30px;
      }

      .text {
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        margin: 25px 0 10px 20px;

        .subtext {
          width: 70%;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }

    .container1 {
      height: 400px;
      background-color: #8c56e1;
      margin-right: 15px;

      .innovate {
        position: absolute;
        top: 38px;
        left: 85%;
        transform: translate(-50%, 100%) rotate(-90deg);
        font-size: 40px;
        font-weight: bold;
        color: #8040d6;
      }

      .button {
        margin: 30px 0 0 25px;
      }
    }

    /* Define styles for the second container */
    .container2 {
      height: 400px;
      background-color: #a4d165;
      margin-right: 15px;

      .sharepass {
        position: absolute;
        top: 50px;
        left: 85%;
        transform: translate(-50%, 100%) rotate(-90deg);
        font-size: 40px;
        font-weight: bold;
        color: #80aa43;
      }

      .button {
        margin: 30px 0 0 25px;
      }
    }

    /* Define styles for the third container */
    .container3 {
      height: 400px;
      background-color: #9caab9;

      .transparency {
        position: absolute;
        top: 100px;
        left: 85%;
        transform: translate(-50%, 100%) rotate(-90deg);
        font-size: 40px;
        font-weight: bold;
        color: #868e97;
      }

      .button {
        margin: 30px 0 0 25px;
      }
    }

    .middle-container {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;

      .small-black-container {
        background-image: linear-gradient(to bottom, #1d1e20 0%, #181b1e 100%);
      }

      .small-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 15%;
        overflow: hidden;

        .navigation {
          color: white;
          margin-right: 1rem;

          .heading {
            font-size: 18px;
            color: #8395a7;
          }
          .body {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 13rem;
            margin-bottom: 2rem;
            margin-top: 2rem;
            span {
              font-size: 28px;
              margin: 0 4px;
            }
            button {
              min-width: 20px;
            }

            .commas {
              position: absolute;
              left: -204px;
              bottom: 0;
            }
          }
        }
      }

      .black-container-img {
        width: 110px;
        margin: 2rem auto;
        padding-top: 40rem;
      }

      .testimonial {
        width: 100%;
        white-space: nowrap;
        display: flex;
        overflow: scroll;

        .testimonial-container {
          background-color: #353c43;
          width: 288px;
          height: 259px;
          padding: 20px;
          border-radius: 35px;
          margin-top: 20px;
          margin-right: 1.25rem;
          flex-shrink: 0;

          .testimonial-body {
            display: flex;
            flex-direction: column;
          }
        }
      }

      /* Define styles for the testimonial text */
      .testimonial-text {
        width: 90%;
        height: 60%;
        font-size: 14px;
        color: #ffffff;
        text-wrap: wrap;
        margin: 0 0 2.25rem 0.75rem;
      }

      /* Define styles for the testimonial info container */
      .testimonial-info {
        display: flex;
        align-items: center;
        margin-left: 0.75rem;
      }

      /* Define styles for the profile image */
      .profile-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 0.625rem;
      }

      /* Define styles for the name */
      .name {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 3px;
      }

      /* Define styles for the stars */
      .stars {
        margin-top: 2px;
      }
      .try-now {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        .button {
          color: #ffff;
          background-color: #8dc63f;
          border: 1px solid transparent;
          padding: 8px 30px;
          font-size: 16px;
          border-radius: 5px;
        }
      }

      .numbers {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;

        .numbers-image {
          width: 100%;
          margin-top: 0;
        }
      }

      .work-title {
        display: flex;
        justify-content: center;
        margin-top: 4rem;

        .whywork-title {
          width: 413px;
        }
      }

      .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #16092b;
        color: white;

        .image {
          margin-top: 5rem;
        }

        .headings {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          flex-direction: column;
          width: 100%;
        }

        .join-community,
        .more-details {
          display: flex;
          flex-direction: column;
          //   align-items: self-start;

          span {
            color: #ffff;
            font-size: 28px;
          }
        }

        .details-links {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          color: #b9b5bf;
          width: 100%;
          margin-top: 2rem;
          padding-left: 0;

          a {
            margin-top: 1rem;
          }

          /* Add the following CSS to arrange the links in two columns */
          .row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 1rem;
          }

          /* Adjust the width of the links to fit two in a row */
          .row a {
            margin-left: 12px;
            text-wrap: nowrap;
          }

          .arrow-right {
            margin-left: 6rem;
          }
        }

        .social-links {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          color: #b9b5bf;
          width: 100%;
          margin-top: 3rem;

          a {
            margin-bottom: 2rem;
          }

          /* Add the following CSS to arrange the links in two columns */
          .row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 1rem;
          }

          /* Adjust the width of the links to fit two in a row */
          .row a {
            width: 100%;
            box-sizing: border-box;
            text-align: center;
          }

          .arrow-right {
            margin-left: 3rem;
          }
        }

        .social-links a,
        .details-links a {
          border-bottom: 2px solid #2d2240;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 80%;

          .facebook,
          .instagram {
            margin-right: 10px;
          }

          .arrow {
            margin: 0 45px;
          }
        }

        .copyright {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 30px;
          background-color: #210d41;

          p {
            color: #7a6e8d;
          }
        }

        .back-top-button {
          position: fixed;
          max-width: 70%;
          right: 0;
          bottom: 588px;
          cursor: pointer;
          transition: transform 0.3s ease;

          &:hover {
            transform: translateY(-5px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .main-container .middle-container .footer-content .back-top-button {
    bottom: 580px;
  }
}

@media only screen and (max-width: 320px) {
  .main-container .middle-container .footer-content .back-top-button {
    bottom: 595px;
  }
}
