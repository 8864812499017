.terms-container {
    width: 100%;
    margin: 0 auto;
  }
  
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
  }
  
  .body {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .img-text {
    position: absolute;
    top: 50%;
    left: 5%;

    h1 {
      font-size: 32px;
      font-weight: bold;
      color: #0e1012;
    }
  
    .sub-heading {
      font-size: 16px;
      color: #4f5a65;
    }
  }
  
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #0e1012;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 18px;
    color: #4f5a65;
    margin-bottom: 20px;
  }
  
  .section {
    margin-bottom: 30px;
  }
  
  h2 {
    font-size: 18px;
    font-weight: bold;
    color: #0e1012;
    margin-bottom: 10px;
  }
  
  ol {
    margin-left: 20px;
  }
  
  li {
    font-size: 18px;
    color: #4f5a65;
  }
  .logo {
    position: absolute;
    top: 25px;
    left: 25px;
    width: 44px; /* Adjust the width of the logo */
    height: auto;
  }
  
  @media screen and (max-width: 500px) {
    .img-text {
      position: absolute;
      top: 50%;
      left: 1%;;

      h1 {
        font-size: 24px;
        font-weight: bold;
        color: #0e1012;
      }
  
      .sub-heading {
        font-size: 14px;
        color: #4f5a65;
      }
    }
  }