@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #8ec63f82;
    box-shadow: 0 0 0 0 #8ec63f82;
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(34, 186, 216, 0);
    box-shadow: 0 0 0 15px rgba(34, 186, 216, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(34, 186, 216, 0);
    box-shadow: 0 0 0 0 rgba(34, 186, 216, 0);
  }
}

.truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}